@import url("https://fonts.googleapis.com/css2?family=Sora:wght@300;400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-sora: "Sora", sans-serif;
  --font-code: "Source Code Pro", monospace;
  --font-grotesk: "Space Grotesk", sans-serif;
  color-scheme: dark;
}

* {
  scroll-behavior: smooth;
}

@layer base {
  body {
    @apply font-sans bg-n-8 text-n-1 text-base;
  }
}

.rotate-45 {
  @apply rotate-[45deg];
}

.rotate-90 {
  @apply rotate-[90deg];
}

.rotate-135 {
  @apply rotate-[135deg];
}

.rotate-180 {
  @apply rotate-[180deg];
}

.rotate-225 {
  @apply rotate-[225deg];
}

.rotate-270 {
  @apply rotate-[270deg];
}

.rotate-315 {
  @apply rotate-[315deg];
}

.rotate-360 {
  @apply rotate-[360deg];
}

.-rotate-45 {
  @apply rotate-[-45deg];
}

.-rotate-90 {
  @apply rotate-[-90deg];
}

.-rotate-135 {
  @apply rotate-[-135deg];
}

.-rotate-180 {
  @apply rotate-[-180deg];
}

.-rotate-225 {
  @apply rotate-[-225deg];
}

.-rotate-270 {
  @apply rotate-[-270deg];
}

.-rotate-315 {
  @apply rotate-[-315deg];
}

.-rotate-360 {
  @apply rotate-[-360deg];
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr; /* Stack items vertically */
    gap-x: 0;
  }

  .UseCaseCard {
    height: auto; /* Let height adjust dynamically */
    margin-bottom: 20px; /* Space between cards */
  }

  .UseCaseCard img {
    object-fit: cover; /* To ensure image fits well */
  }
}

@media (min-width: 769px) {
  .grid {
    grid-template-columns: repeat(4, 1fr); /* Display in 4 columns */
    gap-x: 4;
  }

  .UseCaseCard {
    height: 100%; /* Maintain uniform height */
  }
}
